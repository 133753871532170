html,
body {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  background-color: #fff;
  height: 100%;
  width: 100%;
}

/* Works on Firefox */
* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: transparent #dde1e7;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #dde1e7;
  border-radius: 20px;
}
